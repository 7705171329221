import * as React from 'react'
import Layout from '../components/layout'
// import { StaticImage } from 'gatsby-plugin-image'
import { homeIcon, homePage, homeContainer } from '../components/layout.module.css'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard, faInfo } from '@fortawesome/free-solid-svg-icons'


const AboutHome = () => {
    return (
      <Layout pageTitle="About">

        <div className={homeContainer}>
            <Link to="/about-me" className={homePage}><FontAwesomeIcon className={homeIcon} icon={faIdCard} /><br/>About Lauren</Link>
            <Link to="/about-ausdict" className={homePage}><FontAwesomeIcon className={homeIcon} icon={faInfo} /><br></br>About the AusDICT</Link>
        </div>
  
  
  
        </Layout>
    )
  }

  export default AboutHome